import config from 'config';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { hidePopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';

const Add = ({ data = undefined, reload, coins }: any) => {
  const dispatch = useAppDispatch();
  const [flag, setFlag] = useState(data?.flag);
  const [percent, setPercent] = useState(data?.percent || []);

  const setAll = (value: any, id: string) => {
    const deep = JSON.parse(JSON.stringify(percent));

    const index = deep.findIndex((e: any) => e.bank === id);
    if (index >= 0) {
      deep[index] = {
        ...deep[index],
        value: value,
      };
    } else {
      deep.push({
        bank: id,
        value: value,
      });
    }
    console.log('deep', deep);
    // setAll((prevState: any) => ({
    //   ...prevState,
    //   percent: deep,
    // }));
  };

  const percentList = () => {
    const rows: any = [];

    coins.forEach((e: any) => {
      const value = data?.perncent.find((a: any) => a.bank._id === e._id);

      rows.push(
        <div key={e.symbol} className='inputLogin'>
          <span>{e.symbol}</span>
          <input type='text' defaultValue={value?.percent !== undefined ? value?.percent : ''} onChange={(d) => setAll(d.target.value || '', e._id)} />
        </div>,
      );
    });

    return rows;
  };

  const submit = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/bank${data ? '/' + data._id : ''}`, true)[data ? 'put' : 'post']({
      flag: flag,
      percent: percent
        .map((e: any) => {
          return {
            bank: e.bank._id,
            percent: e.percent,
          };
        })
        .filter((e: any) => e.percent !== ''),
    });

    if (result.code === 200) {
      dispatch(hidePopup());
      dispatch(successAlert('Done!'));
      reload();
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='login'>
      <div className='inputLogin'>
        <span>Flag ID</span>
        <input type='text' defaultValue={data?.flag} onChange={(e) => setFlag(e.target.value)} />
      </div>
      <div className='group'>{percentList()}</div>
      <div className='submit' onClick={() => submit()}>
        Submit
      </div>
    </div>
  );
};

export default Add;
