import config from 'config';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { hidePopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';

const Add = ({ data = undefined, reload, coins }: any) => {
  const dispatch = useAppDispatch();
  const [canToken, setCantToken] = useState(data?.canToken);
  const [rial, setRial] = useState(data?.canToken);
  const [percent, setPercent] = useState(data?.percent || []);

  const [access, setAccess] = useState<any>({
    coin: data?.access?.coin ?? [],
    energy: data?.access?.energy ?? [],
    fast: data?.access?.fast ?? [],
  });

  const setAll = (value: any, id: string) => {
    const deep = JSON.parse(JSON.stringify(percent));

    const index = deep.findIndex((e: any) => e.bank === id);
    if (index >= 0) {
      deep[index] = {
        ...deep[index],
        value: value,
      };
    } else {
      deep.push({
        bank: id,
        value: value,
      });
    }
    console.log('deep', deep);
    // setAll((prevState: any) => ({
    //   ...prevState,
    //   percent: deep,
    // }));
  };

  const percentList = () => {
    const rows: any = [];

    coins.forEach((e: any) => {
      const value = data?.percent?.find((a: any) => a.bank._id === e._id);

      rows.push(
        <div key={e.symbol} className='inputLogin'>
          <span>{e.symbol}</span>
          <input type='text' defaultValue={value?.percent !== undefined ? value?.percent : ''} onChange={(d) => setAll(d.target.value || '', e._id)} />
        </div>,
      );
    });

    return rows;
  };

  const manageAccess = (type: string, value: string) => {
    setAccess((prevState: any) => {
      const index = prevState[type].indexOf(value);
      if (index !== -1) {
        prevState[type].splice(index, 1);
      } else {
        prevState[type].push(value);
      }

      return {
        ...prevState,
        [type]: prevState[type],
      };
    });
  };

  const list = (data: string[], type: string) => {
    const rows: any = [];

    data.map((e) => {
      rows.push(
        <section key={e} onClick={() => manageAccess(type, e)}>
          <i className={access[type]?.includes(e) && 'active'}></i>
          {e}
        </section>,
      );
    });

    return rows;
  };

  const submit = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/user${data ? '/' + data._id : ''}`, true)[data ? 'put' : 'post']({
      canToken: !!parseInt(canToken),
      access: access,
    });

    if (result.code === 200) {
      dispatch(hidePopup());
      dispatch(successAlert('Done!'));
      reload();
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='login'>
      <div className='inputLogin'>
        <span>Email</span>
        <input type='text' defaultValue={data?.email} disabled />
      </div>
      <div className='inputLogin'>
        <span>Allow token</span>
        <select defaultValue={Number(canToken)} onChange={(e) => setCantToken(e.target.value)}>
          <option value={1}>Yes</option>
          <option value={0}>No</option>
        </select>
      </div>
      <div className='inputLogin'>
        <span>Coin Trade</span>
        <div className='groupLabel'>{list(['USDT', 'UUSD', 'cVoucher', 'PM', 'PME', 'TRX'], 'coin')}</div>
      </div>
      <div className='inputLogin'>
        <span>Fast</span>
        <div className='groupLabel'>{list(['trade', 'charge'], 'fast')}</div>
      </div>
      <div className='inputLogin'>
        <span>Energy</span>
        <div className='groupLabel'>{list(['buy'], 'energy')}</div>
      </div>
      <div className='extra'>---</div>
      <div className='group'>
        <div className='inputLogin'>
          <span>Rial Rate</span>
          <select defaultValue={data?.fee?.send?.type} onChange={(e) => setRial(e.target.value)}>
            <option value={'auto'}>Auto</option>
            <option value={'fixed'}>Fixed</option>
            <option value={'percent'}>Percent</option>
          </select>
        </div>
        <div className='inputLogin'>
          <span>Value</span>
          <input type='text' defaultValue={data?.fee?.send?.amount} onChange={(e) => setRial(e.target.value)} />
        </div>
      </div>
      <div className='extra'>---</div>
      <div className='group'>{percentList()}</div>
      <div className='submit' onClick={() => submit()}>
        Submit
      </div>
    </div>
  );
};

export default Add;
